/* eslint-disable */
import React from 'react';
import CookieConsent from "react-cookie-consent";
import CookieWrapper from "./cookie.style";

const Cookie = () => {
    return (
        <CookieWrapper>
            <CookieConsent
                location="bottom"
                buttonText="Akceptuję"
                cookieName="innovcodeCookie"
                style={{background: "rgb(51, 51, 51, 0.9)"}}
                buttonStyle={{
                    color: "#000000",
                    fontSize: "20px",
                    padding: "5px 15px",
                    background: "rgb(242,166,84)",
                    borderRadius: "10px"
                }}
                expires={150}
            >
                Nasza strona wykorzystuje pliki cookies. Zapoznaj się z&nbsp;
                <a href="/polityka-prywatnosci">Polityką Prywatności</a>.
                <br/> Klikając „Akceptuję” wyrażasz zgodę na stosowanie plików cookies zgodnie z&nbsp;
                <a href="/polityka-prywatnosci">Polityką Prywatności</a>.
            </CookieConsent>
        </CookieWrapper>
    );
};

export default Cookie;
