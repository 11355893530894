import styled from 'styled-components';

const CookieWrapper = styled.div`
  a {
    color: #fff;
    text-decoration: underline;
    font-weight: bold;
  }
  
  .CookieConsent div:nth-child(2) {
    margin: auto;
  }
`;

export default CookieWrapper;
